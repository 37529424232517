

































































































































import { computed, defineComponent, reactive, ref, set } from '@vue/composition-api'
import { getProjectApi, getProjectQuotaApi, getProjectQuotaOnboardingPollApi, setProjectQuotaApi, deleteQuotaApi } from '@/api/project'
import { asyncSetTimeout, showError } from '@/utils/common'
import ProjectHeader from './components/ProjectHeader.vue'
import { basicPollItems } from '@/api/project/entity-type-option'
import _ from 'lodash'
import { EntityType, ProjectStatus } from '@/api/project/model'
import { Col, Row } from 'element-ui'
import ErrorMessage from '@/components/ErrorMessage.vue'
import { verifyMessageEntity } from '@/utils/verify-message-entity'

export default defineComponent({
    components: {
        ProjectHeader,
        [Row.name]: Row,
        [Col.name]: Col,
        ErrorMessage
    },
    setup(props, { root }) {
        const projectUuId = computed(() => root.$route.params.projectUuId)
        const isDisabledForm = ref(false)
        const detailObj: any = reactive({})
        const getProject = async () => {
            try {
                const { details, status } = await getProjectApi({
                    uuid: projectUuId.value
                })
                Object.keys(details).forEach((key) => {
                    set(detailObj, key, details[key])
                })
                isDisabledForm.value = [ProjectStatus.Published, ProjectStatus.Start, ProjectStatus.End].includes(status)
            } catch (error) {
                showError(error)
            }
        }
        getProject()

        const quotaList = ref([])

        const getProjectQuota = async () => {
            try {
                const list = await getProjectQuotaApi({
                    uuid: projectUuId.value
                })
                list.forEach((item: any) => {
                    if (item.message_entity) {
                        item.message_entity.options.forEach((obj: any) => {
                            const quota = item.quota.find((v: any) => v.option_id == obj.id)
                            obj.count = quota ? quota.count : 0
                        })
                        item.error = verifyMessageEntity(item)
                    } else {
                        item.error = root.$i18n.t('projectQuota.errorMsg1')
                    }
                })
                quotaList.value = list
            } catch (error) {
                showError(error)
            }
        }
        getProjectQuota()

        const getProjectQuotaOnboardingPoll = async () => {
            try {
                const list = await getProjectQuotaOnboardingPollApi({
                    uuid: projectUuId.value
                })
                list.forEach((item: any) => {
                    item.options.forEach((obj: any) => {
                        obj.count = null
                        obj.rateCopy = undefined
                        obj.limit_number = undefined
                    })
                    if ([EntityType.onboarding_poll, EntityType.onboarding_poll_picture].includes(item.entity_type)) {
                        if (
                            !verifyMessageEntity({
                                message_entity: item
                            })
                        ) {
                            optionInfo.option.push(item)
                            optionInfo.optionCopy.push(item)
                        }
                    }
                })
            } catch (error) {
                showError(error)
            }
        }
        getProjectQuotaOnboardingPoll()

        const optionInfo = reactive({
            value: '',
            option: [] as any[],
            optionCopy: [] as any[],
            itemObj: {} as any,
            change: () => {
                const option = optionInfo.optionCopy.find((v: any) => v.id == optionInfo.value)
                optionInfo.itemObj = _.cloneDeep(option)
            },
            calculateRate: async (option: any) => {
                let otherRate = 0
                optionInfo.itemObj.options.forEach((item: any) => {
                    if (item.id != option.id && item.rate >= 0) {
                        otherRate += item.rate
                    }
                })

                if (otherRate >= 100) {
                    await asyncSetTimeout(() => (option.rate = undefined))
                    // root.$message.warning('总比例不能超过100')
                } else if (otherRate > 0) {
                    const differ = 100 - otherRate
                    if (differ < option.rate) {
                        await asyncSetTimeout(() => (option.rate = differ))
                        // root.$message.warning('总比例不能超过100')
                    }
                }
                optionInfo.calculateLimitNumber()
            },
            calculateLimitNumber: () => {
                const limitNumber = detailObj.limit_number
                let allRate = 100
                optionInfo.itemObj.options.forEach((item: any) => {
                    if (item.rate >= 0) {
                        allRate = allRate - item.rate
                    }
                })
                const length = optionInfo.itemObj.options.filter((item: any) => !(item.rate >= 0)).length
                const average = ~~(allRate / length).toFixed(0)

                let differ = allRate
                let count = 0

                optionInfo.itemObj.options.forEach((item: any) => {
                    if (item.rate >= 0) {
                        item.rateCopy = item.rate
                        item.limit_number = Math.ceil((limitNumber * item.rate) / 100)
                    } else {
                        count++
                        let number
                        if (differ >= average) {
                            if (count == length) {
                                number = differ
                            } else {
                                number = average
                                differ = differ - average
                            }
                        } else {
                            number = differ
                        }

                        item.rateCopy = number
                        item.limit_number = Math.ceil((limitNumber * number) / 100)
                    }
                })
            }
        })

        const submitInfo = reactive({
            isSubmitLoad: false,
            isDisabled: computed(() => {
                const { options } = optionInfo.itemObj
                if (options?.length > 0) {
                    return false
                } else {
                    return true
                }
            }),
            onSubmitFrom: async () => {
                optionInfo.calculateLimitNumber()
                let allQuotas = 0
                const quotas = optionInfo.itemObj.options.map((v: any) => {
                    return {
                        option_id: v.id,
                        count: v.count?v.count:0
                    }
                })


                // const isAllFill = optionInfo.itemObj.options.every((v: any) => v.rate >= 0)
               
                // return
                submitInfo.isSubmitLoad = true
                try {
                    await setProjectQuotaApi({
                        uuid: optionInfo.itemObj.project_uuid,
                        message_entity_id: optionInfo.itemObj.id,
                        quotas: quotas
                    })
                    getProjectQuota()
                    root.$message.success(<string>root.$i18n.t('success'))
                    operateInfo.cancel()
                } catch (error) {
                    showError(error)
                }
                submitInfo.isSubmitLoad = false
            }
        })

        const operateInfo = reactive({
            isShow: false,
            isEdit: false,
            init: () => {
                optionInfo.value = ''
                optionInfo.itemObj = {}
            },
            edit: (itemInfo: any) => {
                optionInfo.option = _.cloneDeep(optionInfo.optionCopy)
                operateInfo.isEdit = true
                const limitNumber = detailObj.limit_number
                optionInfo.value = itemInfo.message_entity_id
                optionInfo.change()
                optionInfo.itemObj.options.forEach((item: any) => {
                    const quota = itemInfo.quota.find((v: any) => v.option_id == item.id)
                   item.count = quota ? quota.count : undefined
                })
                operateInfo.isShow = true
            },
            add: () => {
                optionInfo.option = optionInfo.optionCopy.filter((v) => {
                    return !quotaList.value.map((z: any) => z.message_entity_id).includes(v.id)
                })
                operateInfo.isEdit = false
                operateInfo.isShow = true
                operateInfo.init()
            },
            cancel: () => {
                operateInfo.isShow = false
            }
        })

        const onDeleteQuota = (item: any, index: number) => {
            root.$msgbox({
                showCancelButton: true,
                message: <string>root.$i18n.t('projectQuota.deleteMsg'),
                async beforeClose(action, instance, done) {
                    if (action === 'confirm') {
                        try {
                            instance.confirmButtonLoading = true
                            await deleteQuotaApi({
                                uuid: projectUuId.value,
                                quotaId: item.id
                            })
                            quotaList.value.splice(index, 1)
                            operateInfo.cancel()
                            root.$message.success(root.$t('deleteSuccess').toString())
                            done()
                            instance.confirmButtonLoading = false
                        } catch (error) {
                            showError(error)
                        }
                    } else {
                        done()
                    }
                }
            }).catch(() => {})
        }

        return {
            projectUuId,
            isDisabledForm,
            detailObj,
            quotaList,
            optionInfo,
            submitInfo,
            operateInfo,
            EntityType,
            onDeleteQuota
        }
    }
})
